const ABI = {
    diary: [
	{
	    "inputs": [],
	    "stateMutability": "nonpayable",
	    "type": "constructor"
	},
	{
	    "anonymous": false,
	    "inputs": [
		{
		    "indexed": false,
		    "internalType": "address",
		    "name": "_diaryAddress",
		    "type": "address"
		},
		{
		    "indexed": false,
		    "internalType": "string",
		    "name": "message",
		    "type": "string"
		}
	    ],
	    "name": "Published",
	    "type": "event"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_diaryAddress",
		    "type": "address"
		},
		{
		    "internalType": "uint256",
		    "name": "entryIndex",
		    "type": "uint256"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "editDiaryEntry",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "",
		    "type": "address"
		}
	    ],
	    "name": "myDiary",
	    "outputs": [
		{
		    "internalType": "string",
		    "name": "key",
		    "type": "string"
		},
		{
		    "internalType": "bool",
		    "name": "isSealed",
		    "type": "bool"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_diaryAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "_key",
		    "type": "string"
		}
	    ],
	    "name": "publish",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_address",
		    "type": "address"
		}
	    ],
	    "name": "readDiary",
	    "outputs": [
		{
		    "components": [
			{
			    "internalType": "string",
			    "name": "entry",
			    "type": "string"
			},
			{
			    "internalType": "uint256",
			    "name": "timestamp",
			    "type": "uint256"
			}
		    ],
		    "internalType": "struct Genesis.DiaryEntry[]",
		    "name": "_entries",
		    "type": "tuple[]"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [],
	    "name": "version",
	    "outputs": [
		{
		    "internalType": "uint8",
		    "name": "",
		    "type": "uint8"
		}
	    ],
	    "stateMutability": "view",
	    "type": "function"
	},
	{
	    "inputs": [
		{
		    "internalType": "address",
		    "name": "_diaryAddress",
		    "type": "address"
		},
		{
		    "internalType": "string",
		    "name": "entry",
		    "type": "string"
		}
	    ],
	    "name": "writeToDiary",
	    "outputs": [],
	    "stateMutability": "nonpayable",
	    "type": "function"
	}
    ],
}

export default ABI;