import React from "react";
import styles from "./animation.module.css";
import PropTypes from "prop-types";

function LoadingHexagon(props){

    let {children, textDirection} = props;


    return(
	<>
	    <div className={styles.cssloadWrap + " loadingHexgon"}>
		{
		    textDirection && textDirection ==='top'
			?
			<>
			    <div className={styles.cssloadText}>
				{children}
			    </div>
			    <div className={styles.cssloadCssloadSpinner} />
			</>
			:
			<>
			    <div className={styles.cssloadCssloadSpinner} />
			    <div className={styles.cssloadText}>
				{children}
			    </div>
			</>
		}
	    </div>
	</>
    )

}
LoadingHexagon.propTypes = {
    children: PropTypes.node,
    textDirection: PropTypes.string
}
export default LoadingHexagon;