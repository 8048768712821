import React, {createContext, useContext, useEffect, useState} from "react";
import EthCrypto from 'eth-crypto';
import {utils, Wallet} from "ethers";

import Web3 from "web3";
// import genesisTemplate from "./genesisCreator/genesis_template.json";
import PropTypes from "prop-types";
import {useWeb3React} from "@web3-react/core";
import {HDNode} from "ethers/lib/utils";

const DiaryContext = createContext(undefined);


export function DiaryProvider({children}) {

    const {activate, deactivate, active, chainId, account, library} = useWeb3React();

    console.log(library)
    const web3 = new Web3(library.provider);

    const [key] = useState("diary")
    const [storage] = useState(localStorage);
    // console.log(storage)

    const [id, setId] = useState({})
    const [unlocked, setUnlocked] = useState(false);

    /**
     * Creates a new KeyPair, Public & Private key with no Password of secure
     * @returns {{user: {privateKey: string, address: string}}}
     * @deprecated
     */
    const createIdentity = (password) => {
	let account = web3.eth.accounts.create(password); //Creating Account with no password
	let id = {
	    user: {
		address: account.address,
		pubicKey: account.address,
		privateKey: account.privateKey
	    },
	}
	setId(id)
	return id;
    }

    // const recreateIdentity = (password) => {
	// let id = createIdentity(password);
	// setPrivateKeyToDecryptDiary(id.user.privateKey)
	// // storage.setItem(key, JSON.stringify(id));
	// // setId(id)
	// // if(JSON.parse(storage.getItem(key)).user.address.length > 0) return true;
	// // else return false;
    // }

    // const setPrivateKeyToDecryptDiary = (privateKey) => {
	// if (privateKey.length > 0) {
	//     console.log("Setting new Key to Decrypt", privateKey)
    //
	//     const publicKey = EthCrypto.publicKeyByPrivateKey(privateKey)
	//     const address = EthCrypto.publicKey.toAddress(publicKey);
    //
	//     let id = {
	// 	user: {
	// 	    address: address,
	// 	    pubicKey: address,
	// 	    privateKey: privateKey
	// 	}
	//     }
	//     // storage.setItem(key, JSON.stringify(id));
	//     setId(id);
	//     setDiaryOwner()
	// }
    // }
    //
    // const setDiaryOwner = () => {
	// diary.owner = {
	//     address: JSON.parse(storage.getItem(key)).user.address,
	//     publicKey: JSON.parse(storage.getItem(key)).user.address,
	//     privateKey: JSON.parse(storage.getItem(key)).user.privateKey
	// }
    // }

    const reset = () => {
	setId({})
	setUnlocked(false)
    }

    const setDiaryToPreview = (privateKey) => {
	if (privateKey.length > 0) {
	    const publicKey = EthCrypto.publicKeyByPrivateKey(privateKey)
	    const address = EthCrypto.publicKey.toAddress(publicKey);

	    let id = {
		owner: {
		    address: address,
		    publicKey: address, //What if using publicKey from node_save?
		    privateKey: privateKey
		}
	    }
	    setId(id);
	    setUnlocked(true)
	}
    }

    /**
     * Initial check if no ID exist
     */
    // if (!storage.getItem(key) || JSON.parse(storage.getItem(key)).user === undefined) {
	// recreateIdentity("")
    // }

    const diary = {
	owner: id.owner,

	isUnlocked: () => {return unlocked},
	hasIdentity: () => {return !(!storage.getItem(key) || JSON.parse(storage.getItem(key)).user === undefined)},

	create: (password) => {

	    /**
	     * 1.) Create Mnemonic (random-words)
	     * 2.) Create Wallet with Mnemonic and Passphrase
	     * 3.) Never store private-key, always hold passphrase temporary because Mnemonic will be stored in localStorage
	     */
	    const wallet = Wallet.createRandom();
	    let id = {
		user: {
		    mnemonic: wallet.mnemonic.phrase,
		    locale: wallet.mnemonic.locale,
		    path: wallet.mnemonic.path
		}
	    }

	    storage.setItem(key, JSON.stringify(id))

	    return id;
	},
	__restoreWallet: (identity, password) => {
	    const node_save = HDNode.fromMnemonic(identity.user.mnemonic, password, identity.user.locale).derivePath(identity.user.path)
	    let id = {
		owner: {
		    address: node_save.address,
		    publicKey: node_save.address, //What if using publicKey from node_save?
		    privateKey: node_save.privateKey
		}
	    }
	    setId(id);
	    setUnlocked(true)
	    return id;
	},

	    /**
	     * Unlocks the Diary with a necessary Password
	     * @param password
	     */
	unlockDiary: async(password = "") => {

	    if(password === null || password === ""){
		throw Error("Password is empty!")
	    }else if(password.length < 5){
		throw Error("Password is to short!")
	    }else{

		if(diary.hasIdentity()){
		    //
		    // console.log("Has Identity: ", password)
		    let identity = JSON.parse(storage.getItem(key));
		    return diary.__restoreWallet(identity, password);

		}else{
		    //1.) Create Mnemonic and save
		    console.log("Create Mnemonic first", password)
		    const identity = diary.create();

		    const node_original = HDNode.fromMnemonic(identity.user.mnemonic, null, identity.user.locale).derivePath(identity.user.path)
		    console.log("Unsave: ", node_original.address)
		    return diary.__restoreWallet(identity, password);
		}
	    }

	},
	lockDiary: () => {
	    setId({})
	    setUnlocked(false)
	},

	/**
	 * Encrypts a specific String with the EC-publicKey
	 * Verschluesselt einen bestimmten String mit dem EC-publicKey
	 * @param messageToEncode
	 * @returns {Promise<string>}
	 */
	encryptMessage: async (messageToEncode) => {
	    const publicKey = EthCrypto.publicKeyByPrivateKey(diary.owner.privateKey)
	    const address = EthCrypto.publicKey.toAddress(publicKey);
	    // console.log("Encrypt Message with Address: ", address)
	    let msg = await EthCrypto.encryptWithPublicKey(publicKey, messageToEncode);
	    console.log(EthCrypto.cipher.stringify(msg))

	    return EthCrypto.cipher.stringify(msg)
	},
	decryptMessage: async (messageToDecrypt) => {

	    if (web3.utils.isHex(messageToDecrypt)) {
		try {
		    return EthCrypto.decryptWithPrivateKey(
			diary.owner.privateKey,
			EthCrypto.cipher.parse(messageToDecrypt)
		    ).then((decryptedMessage) => {

			if(decryptedMessage === '')
			    return "<span class='empty'/>"
			else
			    return decryptedMessage;
		    }).catch((error) => {
			console.error(error)
			// throw Error("Cannot decode, regarding missing correct privateKey")
			return "<span class='insecure'>"+messageToDecrypt+"</span>";
		    })
		} catch (e) {
		    console.error(e)
		    // throw Error("Cannot decode, regarding missing correct privateKey")
		    //return messageToDecrypt;
		    return "<span class='insecure'>"+messageToDecrypt+"</span>";
		}
	    } else {
		console.warn("Unsecured Msg detected: ", messageToDecrypt)
		return "<span class='insecure'>"+messageToDecrypt+"</span>";
	    }
	},

    }

    // if(factory.grantedRoles.ADMIN_ROLE || factory.grantedRoles.DEV_ROLE ||factory.grantedRoles.TESTER_ROLE){
	return (
	    <DiaryContext.Provider
		value={{
		    diary,
		    unlocked,
		    setDiaryToPreview,
		    reset,
		    web3
		}}
	    >
		{children}
	    </DiaryContext.Provider>
	)
    // }else{
	// return <RestrictedPage />
    // }



}

DiaryProvider.propTypes = {
    children: PropTypes.node
}


export function useDiary() {

    const context = useContext(DiaryContext);
    if (!context) throw Error("useDiary can only be used with DiaryProvider component")
    return context;

}