import styled from "styled-components";

export const SelectionDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
    	font-size: 0.8em;

	h1{
	    font-size: 1.5em;
	}
    }
`;

export const ViewSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
    align-items: center;
    justify-content: center;
  
    h1{
      height: 60px;
    }

    
  
`;

export const SelectionTile = styled.div`
    height: max-content;
    width: 200px;
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    color: ${(props) => props.theme.global.base};
    text-align: center;
    cursor: pointer;

  	
  
    &:hover{
    	background-color: ${(props) => props.theme.global.base+"CC"};
      	color: white;
    }

    @media screen and (max-width: 768px) {
      width: 100px;
      img{
	width: 40px !important;
      }
      
    }
  
`;