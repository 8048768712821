import styled from "styled-components";

export const EditorStyling = styled.div`
  
     display: grid;
    //flex-direction: row;
    height: fit-content;
    grid-template-columns: 85% auto;
  
    //p, h3{
    //  padding: unset;
    //}
  
  
    .ck-editor{
      //width: 100%;
      height: 100%;
      //width: calc(90vh / 1.4);
    }
  
    .ck-editor__editable {
      	//height: 100%;
    	height: calc( var(--editor-height) - 41px);

	//@media (max-width: 768px) {
	//    min-height: 100px;
	//}
  
      	//width: calc(100% - 100px);
    }

  .ck-editor__editable.ck-focused {
    //border: unset !important;
  }
  
    .sendBtn {
      //width: 20%;
      width: 100%;
      border: 1px solid grey;
      background-color: ${(props) => props.theme.global.base};
      color: white;
      flex-direction: column;
    }
    .sendBtn:hover{
      //background-color: lightgrey;
      background-color: ${(props) => props.theme.global.base+"CD"};
      //color: ${(props) => props.theme.global.base};
    }
`;

export const OptionPanel = styled.div`
    position: relative;
    width: 400px;
    height: 100%;
`;

export const ReleaseDiv = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
`;

export const DiaryEntrySection = styled.section`
    
    //border-radius: 5px;
    padding: 10px;
    //height: 100%;
    overflow: auto;
  
    > div > *{
      //padding-left: 10px;
    }

    > div > hr {
      opacity: 0.1;
    }
    span[class='insecure']:before{
      content: 'INSECURE Msg - ';
      color: red;
    }
    span[class='insecure']{
      color: lightgrey;
    }

    span[class='empty']:before{
    	content: '-'
    }
  
    span[class='empty']{
      color: lightgrey;
      font-style: italic;
    }
    
`;

export const UnlockModal = styled.div`

    position: absolute;
    height: 100%;
    width: 100%;
    
    align-items: center;
    justify-items: center;
    
    //color: white;
  
    backdrop-filter: blur(10px);
    //border: 1px solid green;
  
    div[id="content"]{
      	position: relative;
    	display: flex;
      	flex-direction: column;
      	justify-content: space-evenly;
      	align-items: center;
      
      	padding: 20px;
	width: 50%;
      	height: 50%;
      	background-color: lightgrey;
      	border-radius: 10px;
    }
    div[id="closeBtn"]{
      	position: absolute;
      	display: flex;
      	justify-content: center;
      	align-items: center;
      	top: 0px;
      	right: 0;
      	transition: 0.2s;
      	height: 40px;
      	width: 40px;
      	border-top-right-radius: 10px;
      	color: black;
      	cursor: pointer;
    }
    div[id="closeBtn"]:hover{
    	background-color: lightcoral;
      	color: white;
    }
  
    //.MuiInputBase-root{
    //  > input {
	//color: white;
    //  }
    //  > fieldset {
	//border-color: white;
    //  }
    //}
        
`;