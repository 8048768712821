import React, {useEffect, useState} from 'react';
import {DiaryProvider} from "./components/diary/DiaryProvider";
// import MyDiary from "./components/diary/Diary";
import {useWeb3React} from "@web3-react/core";
import {Chain, NetworkID, Web3Connector} from "./utils/NetworkUtils";
import Addresses from "./components/addresses";
import LoadingHexagon from "./components/common/loadingHexagon/LoadingHexagon";
import MyDiary from "./components/diary/Diary";
import ABI from "./components/abi";
import ErrorPage from "./components/common/errorPage/ErrorPage";
import RestrictedPage from "./components/common/restrictedPage/RestrictedPage";
import {Button} from "@mui/material";
import Logo from "./components/common/logo/Logo";
import Images from "./components/common/imageFetcher/Image";
import PublishedDiaries from "./components/published/PublishedDiaries";
import PropTypes from "prop-types";
import ViewSelection from "./components/viewSelectionPage/ViewSelection";

function App(props){

    const { activate, deactivate, active, chainId, connector, account, library } = useWeb3React();
    const [mainNetwork, setMainNetwork] = useState(NetworkID.POLYGON_ZKEVM);
    const [networkError, setNetworkError] = useState(false);

    useEffect(() => {
	activate(Web3Connector).then(response => {
	    console.log(response)
	}).catch(error => {
	    console.error(error)
	})

	if(window.ethereum){
	    console.log("Init Network-Change detector")
	    window.ethereum.on('networkChanged', (chainId) => {
		console.log("Network Changed!", chainId, "Switch to ->", Chain.getNetwork(Number(chainId)))
		setMainNetwork(Chain.getNetwork(Number(chainId)))
	    })
	}

    }, [])

    useEffect(() => {

	console.log("Library Changed")
	if(library){
	    if(chainId !== Chain.getChainId(mainNetwork)){
		switchNetwork(mainNetwork).then(response => {
		    console.log("Switched Network: ", response)
		}).catch((error) => {
		    console.error("Error", error)
		    setNetworkError(true);
		})
	    }
	}

    }, [library])

    const switchNetwork = async(network) => {
	try{
	    await library.provider.request({
		method: "wallet_switchEthereumChain",
		params: [{chainId: network.id}]
	    })
	}catch(switchError){
	    console.error("SwitchError: ", switchError)
	    let errorObj = {
		/**
		 * 4001: User rejected request
		 * 4902:
		 * -32002: wallet_switchEthereumChain already pending
		 */
		code: switchError.code,
		message: switchError.message
	    }
	    console.log(errorObj);
	    throw errorObj;
	}
    }

    const fallback_switchNetwork = async(network) => {
	if(window.ethereum && window.ethereum.isMetaMask){
	    let chainId = await window.ethereum.request({
		method: 'wallet_switchEthereumChain',
		params: [{ chainId: network.id }], // chainId must be in hexadecimal numbers
	    });
	    if(chainId === mainNetwork.chainId){
		setNetworkError(false)
	    }
	}
    }

    if(active){

	if(chainId === mainNetwork.chainId){
	    return(
		<DiaryProvider>
		    <ViewSelection mainNetwork={mainNetwork}/>
		    {/*<MyDiary address={Addresses["DIARY_ADDRESS_"+mainNetwork.id]} abi={ABI.diary}/>*/}
		</DiaryProvider>
	    )
	}else{
	    if(networkError){
		// return <ErrorPage/>
		return(
		    <RestrictedPage info={"Wrong Network"} >
			<p>In order to use this app, switch to the correct Network.</p>
			<br/>
			<Button
			    startIcon={<Logo src={Images.getImageSource(mainNetwork.chainId) } style={{width: "1.6em"}}/>}
			    variant={"outlined"}
			    style={{width: "100%"}}
			    onClick={() => {fallback_switchNetwork(mainNetwork)} }
			>
			    Switch to {mainNetwork.name}
			</Button>

			{/*<p>Thank you for your patient,</p>*/}
			{/*<p>Your Cryptago-Team</p>*/}
		    </RestrictedPage>
		)
	    }else{
		return <LoadingHexagon>Loading Diary...</LoadingHexagon>
	    }
	}

    }else{

	return(
	    <RestrictedPage info={"Please activate your Web3-Provider"} >
		<p>In order to use this app, you need to enable your web3 provider.</p>
		<br/>
		<p>Status: { (active) ? "Actived" : "Pending" }</p>
		<br/>
		<p>Thank you for your patient,</p>
		<p>Your Cryptago-Team</p>
	    </RestrictedPage>
	)
    }
}

export default App;