import React, {useState} from "react";
import MyDiary from "../diary/Diary";
import Addresses from "../addresses";
import ABI from "../abi";
import PublishedDiaries from "../published/PublishedDiaries";
import PropTypes from "prop-types";
import {SelectionDiv, SelectionTile, ViewSelectionDiv} from "./ViewSelection.styles";
import {Button} from "@mui/material";
import Logo from "../common/logo/Logo";

function ViewSelection(props){

    const VIEW = {
	NONE: "NONE",
	PUBLISHED: "PUBLISHED",
	MYDIARY: "MYDIARY",
    }
    const [view, setView] = useState(VIEW.NONE);

    const renderSelection = () => {
	return (
	    <SelectionDiv>
		<h1>Where do you want to go?</h1>
		<ViewSelectionDiv>
		    <SelectionTile onClick={() => {setView(VIEW.MYDIARY)} }>
			<Logo src={"images/diary.svg"} style={{backgroundColor: "transparent", paddingBottom: "10px", width: "64px"}}/>
			<h2>My Diary</h2>
		    </SelectionTile>
		    <SelectionTile onClick={() => {setView(VIEW.PUBLISHED)} }>
			<Logo src={"images/library.svg"} style={{backgroundColor: "transparent", paddingBottom: "10px", width: "64px"}}/>
			<h2>Library</h2>
		    </SelectionTile>

		    {/*<div>*/}
		    {/*    <Button variant={"contained"} onClick={() => {setView(VIEW.MYDIARY)} }>*/}
		    {/*	MY DIARY*/}
		    {/*    </Button>*/}
		    {/*    <Button variant={"outlined"} onClick={() => {setView(VIEW.PUBLISHED)} }>*/}
		    {/*	Show published Diaries*/}
		    {/*    </Button>*/}
		    {/*</div>*/}

		</ViewSelectionDiv>
	    </SelectionDiv>

	)
    }

    const renderMyDiary = () => {
	return <MyDiary address={Addresses["DIARY_ADDRESS_"+props.mainNetwork.id]} abi={ABI.diary} back={() => setView(VIEW.NONE)}/>
    }

    const renderPublished = () => {
	return (
	    <PublishedDiaries
	    	address={Addresses["DIARY_ADDRESS_"+props.mainNetwork.id]} abi={ABI.diary}
		back={() => setView(VIEW.NONE)}
	/>)
    }

    const renderPreview = () => {
	return <h1>Diary Preview</h1>
    }

    const renderSelectionView = () => {
	switch(view){
	    case VIEW.NONE: 		return renderSelection();
	    case VIEW.MYDIARY: 		return renderMyDiary();
	    case VIEW.PUBLISHED:  	return renderPublished();
	}
    }

    return renderSelectionView();
}
ViewSelection.propTypes = {
    mainNetwork: PropTypes.any
}

export default ViewSelection;