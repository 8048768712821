import {InjectedConnector} from "@web3-react/injected-connector";

const supportedChainIds = [
    1,
    4,
    5,
    11155111,
    137,	//
    56,		//Binance
    // 250,
    43114,	//Avalanche-C-Chain
    1422,	//zkEVM-Polygon-Mango
    1442,	//zkEVM-Polygon

    4854	//LOCALHOST
];
export const Web3Connector = new InjectedConnector({ supportedChainIds });

export const NetworkID = {
    ETH: 		{ id: "0x1", 	name: "Ethereum", 	chainId: 1	},		// 1
    POLYGON: 		{ id: "0x89", 	name: "Polygon",  	chainId: 137	},		// 137
    BSC: 		{ id: "0x38", 	name: "Binance", 	chainId: 56	},		// 56
    FTM: 		{ id: "0xfa", 	name: "Fantom", 	chainId: 250	},		// 250
    AVAX: 		{ id: "0xa86a", name: "Avalanche",	chainId: 43114	},		// 43114
    GOERLI: 		{ id: "0x5", 	name: "Goerli", 	chainId: 5	},		// 5
    SEPOLIA: 		{ id: "0xaa36a7", name: "Sepolia",  	chainId: 11155111},
    POLYGON_ZKEVM_OLD: 	{ id: "0x58e", name: "zkEVM_Polygon",  	chainId: 1422},
    POLYGON_ZKEVM: 	{ id: "0x5a2", name: "zkEVM_Polygon",  	chainId: 1442},

    LOCALHOST: 	{ id: "0x12f6", name: "Localhost", 	chainId: 4854	},
}

export const Chain = {

    getNetwork : (chainId) => {
        switch (chainId) {
            case NetworkID.ETH.chainId              :   return NetworkID.ETH;
            case NetworkID.GOERLI.chainId           :   return NetworkID.GOERLI;
            case NetworkID.SEPOLIA.chainId          :   return NetworkID.SEPOLIA;
            case NetworkID.POLYGON.chainId          :   return NetworkID.POLYGON;
            case NetworkID.BSC.chainId              :   return NetworkID.BSC;
            case NetworkID.FTM.chainId              :   return NetworkID.FTM;
            case NetworkID.AVAX.chainId             :   return NetworkID.AVAX;
            case NetworkID.POLYGON_ZKEVM.chainId    :   return NetworkID.POLYGON_ZKEVM;

            case NetworkID.LOCALHOST.chainId        :   return NetworkID.LOCALHOST;
        }
    },

    getChainId : (network) => {
        switch (network) {
            case NetworkID.ETH                      :   return NetworkID.ETH.chainId;
            case NetworkID.GOERLI                   : 	return NetworkID.GOERLI.chainId;
            case NetworkID.SEPOLIA                  : 	return NetworkID.SEPOLIA.chainId;
            case NetworkID.POLYGON                  : 	return NetworkID.POLYGON.chainId;
            case NetworkID.BSC                      : 	return NetworkID.BSC.chainId;
            case NetworkID.FTM                      :	return NetworkID.FTM.chainId;
            case NetworkID.AVAX                     : 	return NetworkID.AVAX.chainId;
            case NetworkID.POLYGON_ZKEVM            : 	return NetworkID.POLYGON_ZKEVM.chainId;

            case NetworkID.LOCALHOST                :   return NetworkID.LOCALHOST.chainId;
        }
    }

}