
const strings = {
    DIARY_TITLE: "My Diary",
    DIARY_LOCKED: "Your diary is locked.",
    LOCK_IDENTITY_EXIST: "Enter your password to unlock it.",
    LOCK_IDENTITY_NOT_EXIST: "Enter a password in order to create your own personal diary."
}

const Diary = {
    strings: strings
}

export default Diary;