import {useWeb3React} from "@web3-react/core";
import {Contract} from "ethers";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import MyDiary from "../diary/Diary";
import {DiaryInfoDiv, DocumentDiv, ExitDiv, LibraryDiv} from "./PublishedDiaries.styles";
import {Button} from "@mui/material";

import ReadMoreIcon from '@mui/icons-material/ReadMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useDiary} from "../diary/DiaryProvider";

const VIEW = {
    LOBBY: "LOBBY",
    PREVIEW: "PREVIEW"
}

function PublishedDiaries(props){

    const diary = useDiary();

    const {activate, deactivate, active, chainId, account, library} = useWeb3React();
    let diaryContract = new Contract(props.address, props.abi, library.getSigner());

    const [diaries, setDiaries] = useState([]);
    const [view, setView] = useState(VIEW.LOBBY);
    const [diaryToPreview, setDiaryToPreview] = useState(null);

    useEffect(() => {
	(async() => {
	    let publishedFilter = diaryContract.filters.Published();
	    let events = await diaryContract.queryFilter(publishedFilter);
	    let fetchedDiaries = [];

	    for(let event of events) {
		let publishedDiary = event.args._diaryAddress;
		let diaryObj = await diaryContract.myDiary(publishedDiary);
		let diary = {
		    sealed: diaryObj.isSealed,
		    publicKey: publishedDiary,
		    privateKey: diaryObj.key
		};
		fetchedDiaries.push(diary)
	    }
	    setDiaries(fetchedDiaries);
	})();
    }, [])

    const setPreviewView = (diaryToPreview) => {
	setDiaryToPreview(diaryToPreview);
	setView(VIEW.PREVIEW);
	console.log(diary)
	diary.setDiaryToPreview(diaryToPreview.privateKey);
    }

    if(view === VIEW.PREVIEW){
	return(
	    <div>
		{/*<h1>{diaryToPreview.publicKey}</h1>*/}
		<MyDiary address={props.address} abi={props.abi} back={() => {
		    setView(VIEW.LOBBY)
		    diary.reset();
		}}/>
	    </div>

	)
    }else if(view === VIEW.LOBBY){
	return(
	    <div style={{height: "100%", textAlign: "center"}}>
		<ExitDiv onClick={() => props.back()}/>
		<h1>List of published Diaries</h1>

		<LibraryDiv>
		    {
			diaries.map((diary, key) => {
			    return (
				<>
				    <DiaryPreview diary={diary} key={key} number={key} setDiaryForPreview={(diary) => setPreviewView(diary)}/>
				    {/*<hr style={{width: "100%"}}/>*/}
				</>
			    )
			})
		    }
		</LibraryDiv>
	    </div>
	)
    }



}
PublishedDiaries.propTypes = {
    address: PropTypes.string,
    abi: PropTypes.array,
    back: PropTypes.func
}

function DiaryPreview(props){

    const diary = props.diary;

    return (
	<DocumentDiv>
		<h3>Diary #{props.number}</h3>
		<Button variant={"contained"} onClick={() => props.setDiaryForPreview(diary)}>
		    <ReadMoreIcon fontSize={"large"}/>
		</Button>

	    {/*<div name={"hiding"}>*/}
		{/*<Button variant={"contained"} onClick={() => props.setDiaryForPreview(diary)}>*/}
		{/*    <ReadMoreIcon fontSize={"large"}/>*/}
		{/*</Button>*/}
	    {/*</div>*/}
	    {/*<div name={"diaryInfo"}>*/}
		{/*<p>{diary.publicKey}</p>*/}
		{/*<p>{diary.privateKey}</p>*/}
	    {/*</div>*/}
	</DocumentDiv>
    )

}

DiaryPreview.propTypes = {
    diary: PropTypes.object,
    number: PropTypes.number,
    setDiaryForPreview: PropTypes.func
}

export default PublishedDiaries;