import React from 'react';
import {ErrorPageDiv} from "./ErrorPage.styles";
import Logo from "../logo/Logo";
import RestrictedPage from "../restrictedPage/RestrictedPage";
import {useWeb3React} from "@web3-react/core";
import {Chain} from "../../../utils/NetworkUtils";


function ErrorPage(props){

    const { chainId } = useWeb3React();


    return(
	<ErrorPageDiv>
	    <ErrorPageDiv.Content>
		<ErrorPageDiv.Content.Header>
		    <div>
			<Logo logoType={1} style={{width: 30, paddingLeft: 12, paddingRight: 12, backgroundColor: "transparent"}}/>
			<h2>Unsupported Network</h2>
		    </div>
		</ErrorPageDiv.Content.Header>
		<ErrorPageDiv.Content.Body>
		    {/*{*/}
			{/*(chainId)*/}
			{/*    ?*/}
			{/*    <p>Your Web3-Provider is currently using {Chain.getNetwork(chainId).name} as Main-Network.</p>*/}
			{/*    :*/}
			{/*    <p></p>*/}
		    {/*}*/}

		    {/*<div className="grid">*/}
			{/*<label> Current ChainId </label>*/}
			{/*<span> {window.ethereum.networkVersion} ({window.ethereum.chainId}) </span>*/}

			{/*<label> Network Name</label>*/}
			{/*<span> { (chainId) ? Chain.getNetwork(chainId).name : "" }</span>*/}

		    {/*</div>*/}
		    {/*<Button onClick={() => fallback_switchNetwork(mainNetwork)}>*/}
			{/*<div>*/}
			{/*    <img style={{width: 24, height: 24, paddingRight: 6}} src={NetworkImageFetcher.getImage(getNetworkID.inDecimal(mainNetwork))}/>*/}
			{/*    Switch to {mainNetwork.name}*/}
			{/*</div>*/}
		    {/*</Button>*/}

		</ErrorPageDiv.Content.Body>

	    </ErrorPageDiv.Content>
	</ErrorPageDiv>
    )

}

export default ErrorPage;