import styled from "styled-components";

export const ExitDiv = styled.div`
    
    position: absolute;
    content: url("/images/door_closed_bold.svg");
    height: 60px;
    width: 60px;
    
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    //filter: invert(49%) sepia(53%) saturate(2305%) hue-rotate(166deg) brightness(107%) contrast(102%);
  
    &:hover{
	content: url("/images/door_open_bold.svg");
	bottom: 8px;
	//top: calc(100% - 52px);
	transform: scaleY(1.03);
    }
`;

export const LibraryDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  //grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
    gap: 3rem;
    margin: 50px 20%;
  
`;

export const DocumentDiv = styled.div`
    border: 1px solid black;
    width: 100%;
    height: 200px;
    border-radius: 10px;
`;

export const DiaryInfoDiv = styled.div`
    flex: 1 0 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 20px;
  
    text-align: left;
    //border: 1px solid grey;

    height: 100px;
  
    //transition: width 2s;
  
    div[name="diaryInfo"]{
      //width: 100%;
      padding: 0px 10px;
    }
  
    div[name="hiding"]{
	display: flex;
	align-items: center;
	height: 100%;
	//width: 100px;
	overflow: hidden;

      	transition: width 0.3s;
      
      	border-left: 1px solid black;
      	//border-top: 1px solid black;
      	//border-bottom: 1px solid black;
	
    	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
      
      button{
	height: 100%;
	width: 100%;
      }
      
    }

  :hover, 
  :focus, 
  :active{
    div[name="hiding"]{
      width: 100px;

      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    div[name="diaryInfo"]{

    }
  }
  
    //:hover{
    //  div[name="hiding"]{
    //    width: 100px;
    //   
    //    border-top-left-radius: 50%;
    //    border-bottom-left-radius: 50%;
    //  }
    //  div[name="diaryInfo"]{
    //    
    //   }
    //}

`;