import styled from 'styled-components';

export const ErrorPageDiv = styled.div`
    height: 100vh;

    //background-color: ${(prop) => prop.theme.global.base_contra};
    
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
  
    font-size: 0.8em;
`;

ErrorPageDiv.Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    //background-color: ${(prop) => prop.theme.global.base};
    
    //border-top-right-radius: 25px;
    //border-top-left-radius: 25px;
    
    //border: 1px solid black;
    height: 300px;
    max-width: 300px;
  
`;

ErrorPageDiv.Content.Header = styled.div`
    height: max-content;
    
    >div{
    	display: grid;
      	grid-template-columns: max-content auto;
	text-align: center;
      	h2 {
	    color: darkorange;
	    margin: 0px;
	    padding: 0.67em;
	    z-index: -1;
	}
    }
`;
ErrorPageDiv.Content.Body = styled.div`
    height: 100%;
    //background-color: ${(prop) => prop.theme.global.base_contra};
    padding: 6px;
    
    display: flex;
    flex-direction: column;
    
    div[class="grid"]{
        height: 100%;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;

        padding: 6px;

    }
    
    button{
        height: 50px;
        color: white;
        background-color: ${(prop) => prop.theme.global.base};
        z-index: 1;
        cursor: pointer;
      
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
        
        
    } 
    button:hover{
        background-color: ${(prop) => prop.theme.global.base_contra};
      	box-shadow: 2px 1px 9px 1px rgba(0,0,0,0.31);
        color: black;
    }
    
`;
