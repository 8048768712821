
const ThemeGlobal = {

    base: "#3498db",
    base_contra: "rgb(211,211,211)",

    background: 	"#ffffff",
    main:		"#1b4474",
    auxiliary_1:	"#0484d4",
    auxiliary_2: 	"#04b3f3"

}

export default ThemeGlobal;