import PropTypes from "prop-types";

class Images{

    getImageSource = (chainId) => {
	let network;

	switch (chainId) {
	    case 1: 	network = "ethereum"; 	  	break;
	    case 4: 	network = "ethereum_rinkeby"; 	break;
	    case 5: 	network = "ethereum_goerli"; 	break;
	    case 137: 	network = "polygon";	  	break;
	    case 56: 	network = "binance";      	break;
	    case 250:	network = "fantom"; 	  	break;
	    case 43114: network = "avax"; 	  	break;
	    case 4854: 	network = "cryptago"; 	  	break;
	    case 1422: 	network = "polygon_zkevm"; 	break;
	    default: 	return 0;
	}

	return "/images/network/"+network+".svg";
    }

}

export default new Images();

// Images.propTypes = {
//     getImageSource: PropTypes.func
// }