import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {LogoDiv, LogoStyledImg} from "./Logo.styles";

function Logo(props){

    // const logoURL = "/images/logo/old/logo.svg";
    const logoURL = "/images/logo/new/";

    const logoType = [
        "logo.svg",
        "logo_only.svg",
        "logo_with_name.svg"
    ]

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimension])

    let src = (props.src) ? props.src : (props.logoType) ? logoURL+logoType[props.logoType] : logoURL+logoType[0]
    let style = props.style;

    if(props.logoType === 0) style.margin = "-18%";

    return(
        <LogoDiv onClick={props.onClick}>
            <LogoStyledImg src={src} style={style} />
            {
                (props.title) ?
                    <label className={(windowDimension.winWidth < 500) ? "fade-out" : "fade-in"}>{props.title}</label>
                    : <></>
            }
        </LogoDiv>
    )

}
Logo.propTypes = {
    onClick: PropTypes.func,
    logoType: PropTypes.number,
    // logoType: PropTypes.oneOf([...(new Array(3))].map((_, i) => i + 1)),
    src: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string
}

export default Logo;

