import './index.css';

import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import {Web3Provider} from "@ethersproject/providers";
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from "web3";

import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import themeLight from "./theme/ThemeLight";
import {ThemeProvider} from "styled-components";
import themeDark from "./theme/ThemeDark";

import("./polyfill.js");

window.web3 = Web3; //Comment this out to disable global Web3

function getLibrary(provider) {
    console.log("GetLibrary", provider)
    const library = new Web3Provider(provider);
    console.log(library)
    library.pollingInterval = 12000;
    return library;
}

// Theme
function WrappedApp(props){

    const [theme, setTheme] = useState(themeLight);
    const toggleTheme = () => { setTheme((curr) => (curr === themeLight ? themeDark : themeLight)); }

    return(
        <Web3ReactProvider getLibrary={getLibrary}>
            {
                <>
                    <ThemeProvider theme={theme}>
                        <App toggleTheme={toggleTheme}/>
                    </ThemeProvider>
                    <ToastContainer
                        position="top-right"
                    />
                </>
            }
        </Web3ReactProvider>
    )

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <WrappedApp/> );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
