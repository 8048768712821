import React from "react";
import Logo from "../logo/Logo";
import {useTheme} from "styled-components";
import PropTypes from "prop-types";

function RestrictedPage(props){

    const theme = useTheme();

    return(
	<div style={{display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center", height: "100%"}}>
	    <div style={{textAlign: "center"}}>
		<Logo logoType={0} style={{backgroundColor: "transparent", width: "400px", zIndex: "-1", marginBottom: "-50px"}} />
		{/*<label style={{fontSize: "1.8rem", color: theme.global.base}}>Cryptago</label>*/}
	    </div>
	    <p>{props.info ? props.info : "--- Sorry only DEVs and Admins are allowed ---"}</p>
	    <section style={{maxWidth: "80%", fontSize: "1em"}}>
		{props.children}
	    </section>
	</div>
    )
}
RestrictedPage.propTypes = {
    info: PropTypes.string,
    children: PropTypes.node
}

export default RestrictedPage;