import {NetworkID} from "../utils/NetworkUtils";

const Addresses = {
    ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
}

Addresses["DIARY_ADDRESS_"+NetworkID.GOERLI.id]         = "0xF1CFdB6E75c1A216462091d8A9A45ab2d6A3932f";
Addresses["DIARY_ADDRESS_OLD"+NetworkID.GOERLI.id]         = "0x948b5BAC99242C7f831475bF6D6a7355615Df4dB";
Addresses["DIARY_ADDRESS_"+NetworkID.LOCALHOST.id]      = "0x72daccfF36E29a4176dbDf0DDEfD822e99A1ed7E";
Addresses["DIARY_ADDRESS_OLD"+NetworkID.POLYGON_ZKEVM_OLD.id]  = "0x17Dd8AE9ab54C7527C34461b3cC2CBFb4fBBbe83";
Addresses["DIARY_ADDRESS_"+NetworkID.POLYGON_ZKEVM.id]  = "0x01794905A8b4DE822d48Cfe07c8Ffe25D52c50d5";


// console.log(Addresses)

export default Addresses;