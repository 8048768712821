
import ThemeGlobal from "./ThemeGlobal";

const themeLight = {

    global: ThemeGlobal,

    // colorBackground: "#f3ebf6",
    textColor: "black",

    footer: "#CDD0C8",

    palette:{

        primary: "#F7F5F0",
        secondary: "#CDD0C8",
        third: "#A0ADA5",
        fourth: "#748B88",

        navPanel: "#CDD0C8",
        content: "rgb(255,255,255)",

        service:{
            online: "#009000"
        }
    },
    icons: {
        background: "#f7f5f0",
    }
}

export default themeLight;