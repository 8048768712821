import React from 'react';
import Logo from "./logo/Logo";
import PropTypes from "prop-types";

function Seal(props){
    return(
	<div style={{position: "relative"}}>
	    <Logo logoType={1} src={"images/seal.svg"} style={{width: props.width}}/>
	    <Logo logoType={1} style={{width: props.width/2, height: "unset", position: "absolute", top: "15%", filter: "grayscale(100%) brightness(0%) contrast(60%) opacity(40%)"}}/>
	</div>
    )
}
Seal.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}

export default Seal;